import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Tour de Romandie",
      edition: 2022,
      what: "Race data",
      updated: "2022-04-26 17:39:29",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      Switzerland: {
        teamId: "Switzerland",
        teamUciCode: "",
        teamName: "Switzerland",
        teamNationCode: "SUI",
      },
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      22925: {
        teamId: "22925",
        teamUciCode: "EKP",
        teamName: "Equipo Kern Pharma",
        teamNationCode: "ESP",
      },
    },
    riders: {
      37259: {
        id: 37259,
        startno: 32,
        firstName: "Gino",
        lastName: "M\u00c3\u00a4der",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-01-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 57,
          overallPosition: 57,
          stageTime: "00:00:27",
          overallTime: "00:00:27",
        },
      },
      16569: {
        id: 16569,
        startno: 147,
        firstName: "Michael",
        lastName: "Woods",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1986-10-12",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 117,
          overallPosition: 117,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      6255: {
        id: 6255,
        startno: 134,
        firstName: "Tsgabu",
        lastName: "Grmay",
        nationCode: "ETH",
        nationName: "Ethiopia",
        birthDate: "1991-08-25",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 114,
          overallPosition: 114,
          stageTime: "00:00:41",
          overallTime: "00:00:41",
        },
      },
      40783: {
        id: 40783,
        startno: 131,
        firstName: "Matteo",
        lastName: "Sobrero",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-14",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 5,
          overallPosition: 5,
          stageTime: "00:00:13",
          overallTime: "00:00:13",
        },
      },
      14722: {
        id: 14722,
        startno: 191,
        firstName: "Mathias",
        lastName: "Fl\u00c3\u00bcckiger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1988-09-27",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 64,
          overallPosition: 64,
          stageTime: "00:00:29",
          overallTime: "00:00:29",
        },
      },
      59918: {
        id: 59918,
        startno: 6,
        firstName: "Brandon",
        lastName: "Rivera",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-03-21",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 62,
          overallPosition: 62,
          stageTime: "00:00:29",
          overallTime: "00:00:29",
        },
      },
      353: {
        id: 353,
        startno: 66,
        firstName: "Anthony",
        lastName: "Roux",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-04-18",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 91,
          overallPosition: 91,
          stageTime: "00:00:34",
          overallTime: "00:00:34",
        },
      },
      5836: {
        id: 5836,
        startno: 164,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 127,
          overallPosition: 127,
          stageTime: "00:00:45",
          overallTime: "00:00:45",
        },
      },
      16690: {
        id: 16690,
        startno: 53,
        firstName: "Tom",
        lastName: "Bohli",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-01-17",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 43,
          overallPosition: 43,
          stageTime: "00:00:24",
          overallTime: "00:00:24",
        },
      },
      2322: {
        id: 2322,
        startno: 22,
        firstName: "Rohan",
        lastName: "Dennis",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-05-28",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 2,
          overallPosition: 2,
          stageTime: "00:00:04",
          overallTime: "00:00:04",
        },
      },
      69317: {
        id: 69317,
        startno: 34,
        firstName: "Johan",
        lastName: "Price-Pejtersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-05-26",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 44,
          overallPosition: 44,
          stageTime: "00:00:24",
          overallTime: "00:00:24",
        },
      },
      37376: {
        id: 37376,
        startno: 37,
        firstName: "Stephen",
        lastName: "Williams",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-06-09",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 104,
          overallPosition: 104,
          stageTime: "00:00:38",
          overallTime: "00:00:38",
        },
      },
      22133: {
        id: 22133,
        startno: 27,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 45,
          overallPosition: 45,
          stageTime: "00:00:24",
          overallTime: "00:00:24",
        },
      },
      2503: {
        id: 2503,
        startno: 52,
        firstName: "Sander",
        lastName: "Armee",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-12-10",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 120,
          overallPosition: 120,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      7781: {
        id: 7781,
        startno: 121,
        firstName: "Carlos",
        lastName: "Verona",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-11-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 125,
          overallPosition: 125,
          stageTime: "00:00:44",
          overallTime: "00:00:44",
        },
      },
      163759: {
        id: 163759,
        startno: 95,
        firstName: "Anton",
        lastName: "Palzer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-03-11",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 71,
          overallPosition: 71,
          stageTime: "00:00:31",
          overallTime: "00:00:31",
        },
      },
      3287: {
        id: 3287,
        startno: 142,
        firstName: "Patrick",
        lastName: "Bevin",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1991-05-02",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 12,
          overallPosition: 12,
          stageTime: "00:00:16",
          overallTime: "00:00:16",
        },
      },
      6415: {
        id: 6415,
        startno: 41,
        firstName: "Louis",
        lastName: "Meintjes",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1992-02-21",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 78,
          overallPosition: 78,
          stageTime: "00:00:31",
          overallTime: "00:00:31",
        },
      },
      39387: {
        id: 39387,
        startno: 105,
        firstName: "Harm",
        lastName: "Vanhoucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-06-17",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 107,
          overallPosition: 107,
          stageTime: "00:00:38",
          overallTime: "00:00:38",
        },
      },
      37391: {
        id: 37391,
        startno: 186,
        firstName: "Diego",
        lastName: "Lopez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-09-12",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 75,
          overallPosition: 75,
          stageTime: "00:00:31",
          overallTime: "00:00:31",
        },
      },
      76245: {
        id: 76245,
        startno: 172,
        firstName: "Ben",
        lastName: "Healy",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "2000-09-11",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 21,
          overallPosition: 21,
          stageTime: "00:00:20",
          overallTime: "00:00:20",
        },
      },
      38169: {
        id: 38169,
        startno: 123,
        firstName: "Johan",
        lastName: "Jacobs",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-03-01",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 29,
          overallPosition: 29,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      117: {
        id: 117,
        startno: 171,
        firstName: "Rigoberto",
        lastName: "Uran",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-01-26",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 27,
          overallPosition: 27,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      20448: {
        id: 20448,
        startno: 86,
        firstName: "Nans",
        lastName: "Peters",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-03-12",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 36,
          overallPosition: 36,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      65515: {
        id: 65515,
        startno: 133,
        firstName: "Kevin",
        lastName: "Colleoni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-11-11",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 109,
          overallPosition: 109,
          stageTime: "00:00:40",
          overallTime: "00:00:40",
        },
      },
      82241: {
        id: 82241,
        startno: 192,
        firstName: "Nils",
        lastName: "Brun",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2000-06-14",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 86,
          overallPosition: 86,
          stageTime: "00:00:33",
          overallTime: "00:00:33",
        },
      },
      82254: {
        id: 82254,
        startno: 97,
        firstName: "Frederik",
        lastName: "Wandahl",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2001-05-09",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 111,
          overallPosition: 111,
          stageTime: "00:00:40",
          overallTime: "00:00:40",
        },
      },
      14712: {
        id: 14712,
        startno: 154,
        firstName: "Romain",
        lastName: "Combaud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-01",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 135,
          overallPosition: 135,
          stageTime: "00:00:48",
          overallTime: "00:00:48",
        },
      },
      6413: {
        id: 6413,
        startno: 83,
        firstName: "Bob",
        lastName: "Jungels",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-09-22",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 33,
          overallPosition: 33,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      102733: {
        id: 102733,
        startno: 7,
        firstName: "Magnus",
        lastName: "Sheffield",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2002-04-19",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 13,
          overallPosition: 13,
          stageTime: "00:00:16",
          overallTime: "00:00:16",
        },
      },
      6245: {
        id: 6245,
        startno: 94,
        firstName: "Patrick",
        lastName: "Konrad",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-10-13",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 93,
          overallPosition: 93,
          stageTime: "00:00:34",
          overallTime: "00:00:34",
        },
      },
      2977: {
        id: 2977,
        startno: 51,
        firstName: "Ion",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-02-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 50,
          overallPosition: 50,
          stageTime: "00:00:25",
          overallTime: "00:00:25",
        },
      },
      713: {
        id: 713,
        startno: 141,
        firstName: "Chris",
        lastName: "Froome",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1985-05-20",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 119,
          overallPosition: 119,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      7729: {
        id: 7729,
        startno: 157,
        firstName: "Martijn",
        lastName: "Tusveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-09-09",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 72,
          overallPosition: 72,
          stageTime: "00:00:31",
          overallTime: "00:00:31",
        },
      },
      10975: {
        id: 10975,
        startno: 137,
        firstName: "Dion",
        lastName: "Smith",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1993-03-03",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 60,
          overallPosition: 60,
          stageTime: "00:00:29",
          overallTime: "00:00:29",
        },
      },
      27332: {
        id: 27332,
        startno: 101,
        firstName: "Steff",
        lastName: "Cras",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-02-13",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 90,
          overallPosition: 90,
          stageTime: "00:00:34",
          overallTime: "00:00:34",
        },
      },
      57355: {
        id: 57355,
        startno: 182,
        firstName: "Urko",
        lastName: "Berrade",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-11-28",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 81,
          overallPosition: 81,
          stageTime: "00:00:32",
          overallTime: "00:00:32",
        },
      },
      95698: {
        id: 95698,
        startno: 24,
        firstName: "Michel",
        lastName: "He\u00c3\u009fmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2001-04-06",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 87,
          overallPosition: 87,
          stageTime: "00:00:33",
          overallTime: "00:00:33",
        },
      },
      6538: {
        id: 6538,
        startno: 17,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 37,
          overallPosition: 37,
          stageTime: "00:00:23",
          overallTime: "00:00:23",
        },
      },
      16587: {
        id: 16587,
        startno: 166,
        firstName: "Alexandr",
        lastName: "Riabushenko",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1995-10-12",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 113,
          overallPosition: 113,
          stageTime: "00:00:41",
          overallTime: "00:00:41",
        },
      },
      346: {
        id: 346,
        startno: 47,
        firstName: "Rein",
        lastName: "Taaram\u00c3\u00a4e",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1987-04-24",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 94,
          overallPosition: 94,
          stageTime: "00:00:34",
          overallTime: "00:00:34",
        },
      },
      6227: {
        id: 6227,
        startno: 72,
        firstName: "Mattia",
        lastName: "Cattaneo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-10-25",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 20,
          overallPosition: 20,
          stageTime: "00:00:20",
          overallTime: "00:00:20",
        },
      },
      56038: {
        id: 56038,
        startno: 197,
        firstName: "Yannis",
        lastName: "Voisard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-07-26",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 59,
          overallPosition: 59,
          stageTime: "00:00:28",
          overallTime: "00:00:28",
        },
      },
      64106: {
        id: 64106,
        startno: 161,
        firstName: "Harold",
        lastName: "Tejada",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-04-27",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 108,
          overallPosition: 108,
          stageTime: "00:00:38",
          overallTime: "00:00:38",
        },
      },
      17203: {
        id: 17203,
        startno: 64,
        firstName: "Quentin",
        lastName: "Pacher",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-01-06",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 66,
          overallPosition: 66,
          stageTime: "00:00:29",
          overallTime: "00:00:29",
        },
      },
      737: {
        id: 737,
        startno: 167,
        firstName: "Andrey",
        lastName: "Zeits",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-12-14",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 112,
          overallPosition: 112,
          stageTime: "00:00:41",
          overallTime: "00:00:41",
        },
      },
      327: {
        id: 327,
        startno: 87,
        firstName: "Michael",
        lastName: "Sch\u00c3\u00a4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 80,
          overallPosition: 80,
          stageTime: "00:00:32",
          overallTime: "00:00:32",
        },
      },
      76065: {
        id: 76065,
        startno: 5,
        firstName: "Lucas",
        lastName: "Plapp",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-12-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 35,
          overallPosition: 35,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      37401: {
        id: 37401,
        startno: 74,
        firstName: "Mikkel",
        lastName: "Honore",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-01-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 11,
          overallPosition: 11,
          stageTime: "00:00:14",
          overallTime: "00:00:14",
        },
      },
      6222: {
        id: 6222,
        startno: 63,
        firstName: "Rudy",
        lastName: "Molard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-17",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 56,
          overallPosition: 56,
          stageTime: "00:00:26",
          overallTime: "00:00:26",
        },
      },
      79955: {
        id: 79955,
        startno: 26,
        firstName: "Gijs",
        lastName: "Leemreize",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-10-23",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 41,
          overallPosition: 41,
          stageTime: "00:00:23",
          overallTime: "00:00:23",
        },
      },
      8068: {
        id: 8068,
        startno: 36,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 55,
          overallPosition: 55,
          stageTime: "00:00:26",
          overallTime: "00:00:26",
        },
      },
      46625: {
        id: 46625,
        startno: 126,
        firstName: "\u00c3\u0093scar",
        lastName: "Rodr\u00c3\u00adguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-05-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 48,
          overallPosition: 48,
          stageTime: "00:00:24",
          overallTime: "00:00:24",
        },
      },
      7998: {
        id: 7998,
        startno: 103,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-12-08",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 103,
          overallPosition: 103,
          stageTime: "00:00:37",
          overallTime: "00:00:37",
        },
      },
      66973: {
        id: 66973,
        startno: 151,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-05-21",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 22,
          overallPosition: 22,
          stageTime: "00:00:20",
          overallTime: "00:00:20",
        },
      },
      64479: {
        id: 64479,
        startno: 122,
        firstName: "Abner",
        lastName: "Gonz\u00c3\u00a1lez",
        nationCode: "PUR",
        nationName: "Puerto Rico",
        birthDate: "2000-10-09",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 122,
          overallPosition: 122,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      31612: {
        id: 31612,
        startno: 84,
        firstName: "Lawrence",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-08-28",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 118,
          overallPosition: 118,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      62984: {
        id: 62984,
        startno: 184,
        firstName: "Carlos",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-07-23",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 130,
          overallPosition: 130,
          stageTime: "00:00:46",
          overallTime: "00:00:46",
        },
      },
      5306: {
        id: 5306,
        startno: 152,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 14,
          overallPosition: 14,
          stageTime: "00:00:16",
          overallTime: "00:00:16",
        },
      },
      6184: {
        id: 6184,
        startno: 65,
        firstName: "Sebastien",
        lastName: "Reichenbach",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1989-05-28",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 79,
          overallPosition: 79,
          stageTime: "00:00:32",
          overallTime: "00:00:32",
        },
      },
      58639: {
        id: 58639,
        startno: 93,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 84,
          overallPosition: 84,
          stageTime: "00:00:33",
          overallTime: "00:00:33",
        },
      },
      97944: {
        id: 97944,
        startno: 85,
        firstName: "Valentin",
        lastName: "Paret-Peintre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-01-14",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 110,
          overallPosition: 110,
          stageTime: "00:00:40",
          overallTime: "00:00:40",
        },
      },
      96981: {
        id: 96981,
        startno: 111,
        firstName: "Antonio",
        lastName: "Tiberi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-06-24",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 19,
          overallPosition: 19,
          stageTime: "00:00:20",
          overallTime: "00:00:20",
        },
      },
      61471: {
        id: 61471,
        startno: 196,
        firstName: "Valere",
        lastName: "Thiebaud",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1999-01-26",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 126,
          overallPosition: 126,
          stageTime: "00:00:44",
          overallTime: "00:00:44",
        },
      },
      10103: {
        id: 10103,
        startno: 73,
        firstName: "Josef",
        lastName: "Cern\u00c3\u00bd",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1993-05-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 30,
          overallPosition: 30,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      27111: {
        id: 27111,
        startno: 71,
        firstName: "Remi",
        lastName: "Cavagna",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-08-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 17,
          overallPosition: 17,
          stageTime: "00:00:17",
          overallTime: "00:00:17",
        },
      },
      3930: {
        id: 3930,
        startno: 116,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 58,
          overallPosition: 58,
          stageTime: "00:00:28",
          overallTime: "00:00:28",
        },
      },
      77599: {
        id: 77599,
        startno: 174,
        firstName: "Sean",
        lastName: "Quinn",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2000-05-10",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 15,
          overallPosition: 15,
          stageTime: "00:00:16",
          overallTime: "00:00:16",
        },
      },
      9302: {
        id: 9302,
        startno: 92,
        firstName: "Felix",
        lastName: "Gro\u00c3\u009fschartner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-12-23",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 3,
          overallPosition: 3,
          stageTime: "00:00:10",
          overallTime: "00:00:10",
        },
      },
      26: {
        id: 26,
        startno: 35,
        firstName: "Luis Le\u00c3\u00b3n",
        lastName: "S\u00c3\u00a1nchez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-12-24",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 69,
          overallPosition: 69,
          stageTime: "00:00:30",
          overallTime: "00:00:30",
        },
      },
      716: {
        id: 716,
        startno: 1,
        firstName: "Geraint",
        lastName: "Thomas",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1986-05-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 4,
          overallPosition: 4,
          stageTime: "00:00:10",
          overallTime: "00:00:10",
        },
      },
      64651: {
        id: 64651,
        startno: 155,
        firstName: "Tim",
        lastName: "Naberman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-05-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 92,
          overallPosition: 92,
          stageTime: "00:00:34",
          overallTime: "00:00:34",
        },
      },
      704: {
        id: 704,
        startno: 132,
        firstName: "Sam",
        lastName: "Bewley",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1987-07-22",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 129,
          overallPosition: 129,
          stageTime: "00:00:45",
          overallTime: "00:00:45",
        },
      },
      5515: {
        id: 5515,
        startno: 44,
        firstName: "Laurens",
        lastName: "Huys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-09-24",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 133,
          overallPosition: 133,
          stageTime: "00:00:47",
          overallTime: "00:00:47",
        },
      },
      46181: {
        id: 46181,
        startno: 183,
        firstName: "Francisco",
        lastName: "Galv\u00c3\u00a1n",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-12-01",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 101,
          overallPosition: 101,
          stageTime: "00:00:37",
          overallTime: "00:00:37",
        },
      },
      168: {
        id: 168,
        startno: 56,
        firstName: "Simon",
        lastName: "Geschke",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-03-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 100,
          overallPosition: 100,
          stageTime: "00:00:37",
          overallTime: "00:00:37",
        },
      },
      37281: {
        id: 37281,
        startno: 91,
        firstName: "Aleksandr",
        lastName: "Vlasov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-04-23",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 18,
          overallPosition: 18,
          stageTime: "00:00:19",
          overallTime: "00:00:19",
        },
      },
      1987: {
        id: 1987,
        startno: 46,
        firstName: "Baptiste",
        lastName: "Planckaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-09-28",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 137,
          overallPosition: 137,
          stageTime: "00:00:56",
          overallTime: "00:00:56",
        },
      },
      5273: {
        id: 5273,
        startno: 112,
        firstName: "Jon",
        lastName: "Aberasturi",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-03-28",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 106,
          overallPosition: 106,
          stageTime: "00:00:38",
          overallTime: "00:00:38",
        },
      },
      1006: {
        id: 1006,
        startno: 31,
        firstName: "Damiano",
        lastName: "Caruso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-10-12",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 31,
          overallPosition: 31,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      46163: {
        id: 46163,
        startno: 11,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 46,
          overallPosition: 46,
          stageTime: "00:00:24",
          overallTime: "00:00:24",
        },
      },
      18575: {
        id: 18575,
        startno: 15,
        firstName: "Fernando",
        lastName: "Gaviria",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-08-19",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 99,
          overallPosition: 99,
          stageTime: "00:00:37",
          overallTime: "00:00:37",
        },
      },
      860: {
        id: 860,
        startno: 61,
        firstName: "Thibaut",
        lastName: "Pinot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-05-29",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 88,
          overallPosition: 88,
          stageTime: "00:00:34",
          overallTime: "00:00:34",
        },
      },
      14126: {
        id: 14126,
        startno: 57,
        firstName: "Davide",
        lastName: "Villella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-06-27",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 96,
          overallPosition: 96,
          stageTime: "00:00:35",
          overallTime: "00:00:35",
        },
      },
      160170: {
        id: 160170,
        startno: 195,
        firstName: "Dario",
        lastName: "Lillo",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2002-04-17",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 68,
          overallPosition: 68,
          stageTime: "00:00:30",
          overallTime: "00:00:30",
        },
      },
      37447: {
        id: 37447,
        startno: 67,
        firstName: "Michael",
        lastName: "Storer",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1997-02-28",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 97,
          overallPosition: 97,
          stageTime: "00:00:35",
          overallTime: "00:00:35",
        },
      },
      264: {
        id: 264,
        startno: 144,
        firstName: "Jakob",
        lastName: "Fuglsang",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-03-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 82,
          overallPosition: 82,
          stageTime: "00:00:32",
          overallTime: "00:00:32",
        },
      },
      4004: {
        id: 4004,
        startno: 115,
        firstName: "Kenny",
        lastName: "Elissonde",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-07-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 116,
          overallPosition: 116,
          stageTime: "00:00:41",
          overallTime: "00:00:41",
        },
      },
      730: {
        id: 730,
        startno: 21,
        firstName: "Steven",
        lastName: "Kruijswijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-06-07",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 95,
          overallPosition: 95,
          stageTime: "00:00:34",
          overallTime: "00:00:34",
        },
      },
      48539: {
        id: 48539,
        startno: 16,
        firstName: "Brandon",
        lastName: "McNulty",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1998-04-02",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 16,
          overallPosition: 16,
          stageTime: "00:00:17",
          overallTime: "00:00:17",
        },
      },
      55807: {
        id: 55807,
        startno: 33,
        firstName: "Hermann",
        lastName: "Pernsteiner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1990-08-07",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 52,
          overallPosition: 52,
          stageTime: "00:00:25",
          overallTime: "00:00:25",
        },
      },
      6409: {
        id: 6409,
        startno: 124,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 70,
          overallPosition: 70,
          stageTime: "00:00:30",
          overallTime: "00:00:30",
        },
      },
      76709: {
        id: 76709,
        startno: 77,
        firstName: "Ethan",
        lastName: "Vernon",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-08-26",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 7,
          overallPosition: 7,
          stageTime: "00:00:14",
          overallTime: "00:00:14",
        },
      },
      16674: {
        id: 16674,
        startno: 135,
        firstName: "Amund Gr\u00c3\u00b8ndahl",
        lastName: "Jansen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-02-11",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 76,
          overallPosition: 76,
          stageTime: "00:00:31",
          overallTime: "00:00:31",
        },
      },
      37777: {
        id: 37777,
        startno: 12,
        firstName: "Ivo",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 49,
          overallPosition: 49,
          stageTime: "00:00:24",
          overallTime: "00:00:24",
        },
      },
      47176: {
        id: 47176,
        startno: 106,
        firstName: "Viktor",
        lastName: "Verschaeve",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-03",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 131,
          overallPosition: 131,
          stageTime: "00:00:46",
          overallTime: "00:00:46",
        },
      },
      114755: {
        id: 114755,
        startno: 185,
        firstName: "Ra\u00c3\u00bal",
        lastName: "Garc\u00c3\u00ada",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-02-23",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 98,
          overallPosition: 98,
          stageTime: "00:00:36",
          overallTime: "00:00:36",
        },
      },
      96148: {
        id: 96148,
        startno: 175,
        firstName: "Georg",
        lastName: "Steinhauser",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2001-10-21",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 8,
          overallPosition: 8,
          stageTime: "00:00:14",
          overallTime: "00:00:14",
        },
      },
      26520: {
        id: 26520,
        startno: 62,
        firstName: "Matteo",
        lastName: "Badilatti",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-07-30",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 134,
          overallPosition: 134,
          stageTime: "00:00:48",
          overallTime: "00:00:48",
        },
      },
      18490: {
        id: 18490,
        startno: 143,
        firstName: "Alexander",
        lastName: "Cataford",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1993-09-01",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 102,
          overallPosition: 102,
          stageTime: "00:00:37",
          overallTime: "00:00:37",
        },
      },
      17215: {
        id: 17215,
        startno: 82,
        firstName: "Geoffrey",
        lastName: "Bouchard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-01",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 121,
          overallPosition: 121,
          stageTime: "00:00:42",
          overallTime: "00:00:42",
        },
      },
      2741: {
        id: 2741,
        startno: 114,
        firstName: "Gianluca",
        lastName: "Brambilla",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-22",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 73,
          overallPosition: 73,
          stageTime: "00:00:31",
          overallTime: "00:00:31",
        },
      },
      63802: {
        id: 63802,
        startno: 193,
        firstName: "Filippo",
        lastName: "Colombo",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-12-20",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 53,
          overallPosition: 53,
          stageTime: "00:00:25",
          overallTime: "00:00:25",
        },
      },
      745: {
        id: 745,
        startno: 2,
        firstName: "Andrey",
        lastName: "Amador",
        nationCode: "CRC",
        nationName: "Costa Rica",
        birthDate: "1986-08-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 74,
          overallPosition: 74,
          stageTime: "00:00:31",
          overallTime: "00:00:31",
        },
      },
      68393: {
        id: 68393,
        startno: 42,
        firstName: "Th\u00c3\u00a9o",
        lastName: "Delacroix",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-02-21",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 34,
          overallPosition: 34,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      16660: {
        id: 16660,
        startno: 145,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 61,
          overallPosition: 61,
          stageTime: "00:00:29",
          overallTime: "00:00:29",
        },
      },
      37494: {
        id: 37494,
        startno: 173,
        firstName: "Neilson",
        lastName: "Powless",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-09-03",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 23,
          overallPosition: 23,
          stageTime: "00:00:20",
          overallTime: "00:00:20",
        },
      },
      44622: {
        id: 44622,
        startno: 104,
        firstName: "Harry",
        lastName: "Sweeny",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-07-09",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 85,
          overallPosition: 85,
          stageTime: "00:00:33",
          overallTime: "00:00:33",
        },
      },
      37219: {
        id: 37219,
        startno: 156,
        firstName: "Casper",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-03-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 47,
          overallPosition: 47,
          stageTime: "00:00:24",
          overallTime: "00:00:24",
        },
      },
      45349: {
        id: 45349,
        startno: 4,
        firstName: "Ethan",
        lastName: "Hayter",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-09-18",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 1,
          overallPosition: 1,
          stageTime: "00:05:52",
          overallTime: "00:05:52",
        },
      },
      27240: {
        id: 27240,
        startno: 136,
        firstName: "Jan",
        lastName: "Maas",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-19",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 124,
          overallPosition: 124,
          stageTime: "00:00:43",
          overallTime: "00:00:43",
        },
      },
      31066: {
        id: 31066,
        startno: 43,
        firstName: "Quinten",
        lastName: "Hermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-29",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 63,
          overallPosition: 63,
          stageTime: "00:00:29",
          overallTime: "00:00:29",
        },
      },
      114659: {
        id: 114659,
        startno: 13,
        firstName: "Juan",
        lastName: "Ayuso",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2002-09-16",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 10,
          overallPosition: 10,
          stageTime: "00:00:14",
          overallTime: "00:00:14",
        },
      },
      16643: {
        id: 16643,
        startno: 96,
        firstName: "Maximilian",
        lastName: "Schachmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-09",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 5,
          overallPosition: 5,
          stageTime: "00:00:13",
          overallTime: "00:00:13",
        },
      },
      82572: {
        id: 82572,
        startno: 146,
        firstName: "Corbin",
        lastName: "Strong",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2000-04-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 83,
          overallPosition: 83,
          stageTime: "00:00:33",
          overallTime: "00:00:33",
        },
      },
      44341: {
        id: 44341,
        startno: 163,
        firstName: "Stefan",
        lastName: "de Bod",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-11-17",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 37,
          overallPosition: 37,
          stageTime: "00:00:23",
          overallTime: "00:00:23",
        },
      },
      27333: {
        id: 27333,
        startno: 3,
        firstName: "Laurens",
        lastName: "De Plus",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-09-04",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 42,
          overallPosition: 42,
          stageTime: "00:00:23",
          overallTime: "00:00:23",
        },
      },
      96297: {
        id: 96297,
        startno: 187,
        firstName: "Pau",
        lastName: "Miquel Delgado",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2000-08-20",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 67,
          overallPosition: 67,
          stageTime: "00:00:30",
          overallTime: "00:00:30",
        },
      },
      20616: {
        id: 20616,
        startno: 165,
        firstName: "Antonio",
        lastName: "Nibali",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-09-23",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 128,
          overallPosition: 128,
          stageTime: "00:00:45",
          overallTime: "00:00:45",
        },
      },
      69502: {
        id: 69502,
        startno: 76,
        firstName: "Mauro",
        lastName: "Schmid",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1999-12-04",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 9,
          overallPosition: 9,
          stageTime: "00:00:14",
          overallTime: "00:00:14",
        },
      },
      77970: {
        id: 77970,
        startno: 127,
        firstName: "Einer",
        lastName: "Rubio",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-02-22",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 105,
          overallPosition: 105,
          stageTime: "00:00:38",
          overallTime: "00:00:38",
        },
      },
      71208: {
        id: 71208,
        startno: 54,
        firstName: "Thomas",
        lastName: "Champion",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-09-08",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 123,
          overallPosition: 123,
          stageTime: "00:00:43",
          overallTime: "00:00:43",
        },
      },
      20511: {
        id: 20511,
        startno: 162,
        firstName: "Valerio",
        lastName: "Conti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 51,
          overallPosition: 51,
          stageTime: "00:00:25",
          overallTime: "00:00:25",
        },
      },
      68214: {
        id: 68214,
        startno: 194,
        firstName: "Antoine",
        lastName: "Debons",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-02-17",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 115,
          overallPosition: 115,
          stageTime: "00:00:41",
          overallTime: "00:00:41",
        },
      },
      42186: {
        id: 42186,
        startno: 81,
        firstName: "Ben",
        lastName: "O'Connor",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-11-25",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 24,
          overallPosition: 24,
          stageTime: "00:00:20",
          overallTime: "00:00:20",
        },
      },
      52665: {
        id: 52665,
        startno: 45,
        firstName: "Julius",
        lastName: "Johansen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-09-13",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 40,
          overallPosition: 40,
          stageTime: "00:00:23",
          overallTime: "00:00:23",
        },
      },
      17182: {
        id: 17182,
        startno: 113,
        firstName: "Julien",
        lastName: "Bernard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-03-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 25,
          overallPosition: 25,
          stageTime: "00:00:20",
          overallTime: "00:00:20",
        },
      },
      41: {
        id: 41,
        startno: 23,
        firstName: "Robert",
        lastName: "Gesink",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-05-31",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 54,
          overallPosition: 54,
          stageTime: "00:00:26",
          overallTime: "00:00:26",
        },
      },
      48008: {
        id: 48008,
        startno: 25,
        firstName: "Sepp",
        lastName: "Kuss",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-09-13",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 77,
          overallPosition: 77,
          stageTime: "00:00:31",
          overallTime: "00:00:31",
        },
      },
      16578: {
        id: 16578,
        startno: 125,
        firstName: "Gregor",
        lastName: "Muhlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1994-04-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 28,
          overallPosition: 28,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      10807: {
        id: 10807,
        startno: 55,
        firstName: "Ruben",
        lastName: "Fernandez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-03-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 26,
          overallPosition: 26,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      27295: {
        id: 27295,
        startno: 75,
        firstName: "James",
        lastName: "Knox",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-11-04",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 39,
          overallPosition: 39,
          stageTime: "00:00:23",
          overallTime: "00:00:23",
        },
      },
      22143: {
        id: 22143,
        startno: 117,
        firstName: "Antwan",
        lastName: "Tolhoek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-04-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 132,
          overallPosition: 132,
          stageTime: "00:00:47",
          overallTime: "00:00:47",
        },
      },
      75998: {
        id: 75998,
        startno: 14,
        firstName: "Finn",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2001-12-21",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 32,
          overallPosition: 32,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
      },
      63035: {
        id: 63035,
        startno: 102,
        firstName: "S\u00c3\u00a9bastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-29",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 89,
          overallPosition: 89,
          stageTime: "00:00:34",
          overallTime: "00:00:34",
        },
      },
      57357: {
        id: 57357,
        startno: 181,
        firstName: "Jos\u00c3\u00a9 F\u00c3\u00a9lix",
        lastName: "Parra",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-01-16",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 136,
          overallPosition: 136,
          stageTime: "00:00:48",
          overallTime: "00:00:48",
        },
      },
      111801: {
        id: 111801,
        startno: 153,
        firstName: "Marco",
        lastName: "Brenner",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2002-08-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-0": {
          stagePosition: 64,
          overallPosition: 64,
          stageTime: "00:00:29",
          overallTime: "00:00:29",
        },
      },
    },
  }

  const race = "Romandie"
  const raceID = 12

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
